import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import styles from './TableTabs.module.scss';
import Button from '../../GlodalComponents/Button/Button'

import icon1 from '../../../assets/images/acctypespage/icon1.svg';
import icon2 from '../../../assets/images/acctypespage/icon2.svg';
import icon3 from '../../../assets/images/acctypespage/icon3.svg';
import icon4 from '../../../assets/images/acctypespage/icon4.svg';

const TableTabs = ({ title, text, tabs }) => {
  const [activeColumn, setActiveColumn] = useState(0);
  const { t } = useTranslation();

  const icons = [icon1, icon2, icon3, icon4]

  const data = t(`${tabs}`, { returnObjects: true });
  const dataTitles = t('acc.first.list1', { returnObjects: true });
  const titles = t('acc.first.list2', { returnObjects: true });
  const columns = Object.keys(data[0]);
  const dataWitoutTitles = t('acc.first.list', { returnObjects: true });

  return (
    <div className={styles.wrap}>
      <div className={`${styles.container} container`}>
        {title && <div className={`${styles.title} font-40-25`}>{t(`${title}`)}</div>}
        {text && <div className={`${styles.text} font-16-14`}>{t(`${text}`)}</div>}
        <div className={styles.responsiveTable}>
          <div className={`${styles.tabs} ${styles.mobbtns}`}>
            {titles.map((item, index) => (
              <button
                key={index}
                className={`${styles.tab} ${activeColumn === index ? styles.active : ''}`}
                onClick={() => setActiveColumn(index)}
              >
                {item.title}
              </button>
            ))}
          </div>

          <div className={styles.table}>
            <div className={`${styles.head} ${styles.flex}`}>
              <div className={`${styles.column} ${styles[`block-0`]}`}></div>
              {dataTitles.map((item, index) => (
                <div 
                  key={index} 
                  className={`${styles.column} ${styles[`block-${index + 1}`]} ${index === activeColumn ? styles.activeColumn : ''}`}
                >
                  <div className={styles.icon}><img src={icons[index]} alt='' /></div>
                  <div className={styles.titlesTitle}>{item.title}</div>
                  <div className={styles.titlesText}>{item.text}</div>
                  <div className={styles.titlesText}>{item.text1}</div>
                  <div className={styles.btn}><Button>{t('btn.open')}</Button></div>
                </div>
              ))}
            </div>
            <div className={styles.body}>
              {dataWitoutTitles.map((row, rowIndex) => (
                <div key={rowIndex} className={styles.flex}>
                  {columns.map((column, index) => (
                    <div 
                      key={index} 
                      className={`${styles.row} ${styles[`block-${index}`]} ${index === activeColumn + 1 ? styles.activeColumn : ''}`}
                    >
                      {row[column]}
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TableTabs;